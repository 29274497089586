$(document).ready(function () {
  $('body').removeClass('no-js');

  // Slick Slider
  $('.slideshow').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  // Slick Slider (inline)
  $('.inline-slideshow').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1
  });

  $(window).on('scroll', function () {
    var yPos = ($(window).scrollTop());
    if (yPos > 200) { // show sticky menu after screen has scrolled down 200px from the top
      $(".navbar").addClass('sticky');
    } else {
      $(".navbar").removeClass('sticky');
    }
  });

  $('.ce_text table').addClass('table').wrap('<div class="table-responsive">')
  $('.select2select').select2({
    minimumResultsForSearch: -1,
    width:'style',
    theme: 'default'
  });
});
